<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import groupTypeServices from '../Script/GroupTypeServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ItemGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
        data: function () {
            return  {
                dataSource:  new kendo.data.DataSource ({
                        page: 1,
                        pageSize: kendo_grid.default_grid_pagesize,
                        serverPaging: false,
                        serverFiltering: false,
                        serverSorting: false,
                        transport: {
                            read: {
                                contentType: "application/json",
                                url: apiBaseUrl,
                                type: "POST",
                                data: function(e) {
                                    return { query: groupTypeServices.readGroupTypeQuery() };
                                },
                                beforeSend: function (req) {
                                    req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                    req.setRequestHeader('Path', window.location.pathname);
                                }
                            },
                            parameterMap: function(options, operation) {
                                return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                            },
                            cache: false,
                        },
                        schema: {
                            data: function (response) {
                                if(response.data.GetGroupType == null){
                                    return [];
                                }else{
                                    return response.data.GetGroupType;
                                }
                            },
                            total: function (response) {
                                if(response.data.GetGroupType == null){
                                    return 0;
                                }else{
                                    return response.data.GetGroupType.length;
                                }
                            },
                        }
                    }),
                columns:  [
                    { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                    { field: "active_flag", title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:center;" },
                        template: this.statusView  }, 
                    { field: "group_type_name", title: "Nama", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "item_group_name", title: "Item Group", headerAttributes:{ style: "text-align:center; font-weight:bold;" } }
                ],
                filterableConfig:  {
                    extra: true,
                    operators: kendo_grid.default_operator
                },
                pageableConfig: kendo_grid.default_pageable_config
            };
        },
        mounted: async function() {
            var deleteClick = this.$props.deleteClick;
            var editClick = this.$props.editClick;
            var grid = this.$refs.grid.kendoWidget();
            var GridElement = grid.element;
            
            GridElement.on("click", "#EditButton", function (e){
                e.preventDefault();
                var dataItem = grid.dataItem(e.target.closest("tr"));
                
                editClick(dataItem, false);
            })

            GridElement.on("click", "#ViewButton", function (e){
                e.preventDefault();
                var dataItem = grid.dataItem(e.target.closest("tr"));
                
                editClick(dataItem, true);
            })

            GridElement.on("click", "#DeleteButton", function (e){
                e.preventDefault();
                var dataItem = grid.dataItem(e.target.closest("tr"));
            
                deleteClick(dataItem.group_type_id);
            })
        },
        methods: {
            columnButton(){
                return this.$globalfunc.gridActionButton("Group Type")
            },
            statusView(e){
                if(e.active_flag == 'N'){
                    return 'New'
                }else if(e.active_flag == 'A'){
                    return 'Active'
                }else if(e.active_flag == 'I'){
                    return 'Inactive'
                }else{
                    return 'Error'
                }
            }
        }
    }
</script>
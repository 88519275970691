import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class GroupTypeService {
    readGroupTypeQuery(){
        var query = `query{
                        GetGroupType{
                            group_type_id
                            group_type_name
                            active_flag
                            item_group_id
                            item_group_name
                        }
                    }`;
        return query;
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: group_type_new!){
                CreateGroupType(New: $data){
                    response
                }
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation($id: String!, $data: group_type_new!){
                UpdateGroupType(GroupType_Id: $id, New: $data ){
                    response
                }
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`
            mutation( $id: String! ){
                DeleteGroupType( GroupType_Id: $id ){
                    response
                }
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    getItemGroupQuery(){
        const query = gql`query{
                        GetItemGroup{
                            item_group_id
                            item_group_name
                        }
                    }`;
        return query;
    }

}

export default new GroupTypeService();